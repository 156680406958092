$(document).on('turbolinks:load', function() {
    function enableInput(element) {
        const parent = element.closest('.js__form-input-disabled__parent')
        if (element.is(':checked')) {
            parent.find('.js__form-input-disabled__input').attr('disabled', false)
        } else {
            parent.find('.js__form-input-disabled__input').attr('disabled', true)
        }
    }

    $('.js__form-input-disabled__checkbox').on('change', function() {
        enableInput($(this));
    });
});
