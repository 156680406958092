import { Sortable } from '@shopify/draggable/lib/draggable.bundle.legacy'

jQuery(document).on('turbolinks:load', function() {
  initDraggable();
})

function initDraggable() {
  const Selectors = {
    BODY: 'body',
    ITEMS_CONTAINER: '.drag-items-container',
    ITEM: '.drag-item',
    MAIN_CONTAINER: '.drag-container'
  };

  const Events = {
    SORT_STOP: 'sortable:stop',
    SORT_SORT: 'sortable:sort',
    DRAG_START: 'drag:start',
    DRAG_STOP: 'drag:stop',
    DRAG_OVER_CONTAINER: 'drag:over:container',
  };

  const mainContainers =  document.querySelectorAll(
    Selectors.MAIN_CONTAINER
  );

  if (!!mainContainers.length) {
    // Initialize Sortable for all reviews in which we specify the container which contains draggable item. In this case there are 2 container i.e published and unpublished.

    mainContainers.forEach(column=> {
      const columnContainers = column.querySelectorAll(
        Selectors.ITEMS_CONTAINER
      );

      updatePublishedCount(columnContainers[0]);
      const sortable = new Sortable(columnContainers, {
        draggable: Selectors.ITEM,
        delay: 200,
        mirror: {
          appendTo: Selectors.BODY,
          constrainDimensions: true,
        }
      });

      // adding event listener for sorting

      sortable.on(Events.DRAG_OVER_CONTAINER, (event) => {
        // toggle slots display and hide upgrade option if the publsihed items are less
        const container = event.data.overContainer;
        const containerParent = container.parentElement
        const isPublishedContainer = isContainerPublished(container)
        // if(isPublishedContainer) {
        //   const items = getFilteredItems(container)
        //   if( items.length <= 1){
        //     containerParent.querySelector('a').style.display = 'none'
        //   }
        //   else
        //     containerParent.querySelector('a').style.display = 'block'
        // }
      })

      sortable.on(Events.SORT_SORT, (event) => {
        // check if container capacity is reached. If the quantity is reached then hide the dropzone from published conatiner.
        const container = event.dragEvent.overContainer;
        const isPublishedContainer = isContainerPublished(container)
        if(!isPublishedContainer)
          return
        const availableSlots = parseInt(container.getAttribute('data-available-slots'));
        const items = getFilteredItems(container)

        var element = container.parentElement.querySelector('.published-media-container .dropzone-container .empty-slot .hc-media__medium span')
        if(items.length >= availableSlots && event.dragEvent.sourceContainer != container) {
          event.cancel();
          element.style.display = 'none'
        } else if(items.length <= availableSlots){
          element.style.display = 'block'
        }

      })

      sortable.on(Events.SORT_STOP, (event) => {
        var sortData = event.data;
        var newContainer = sortData.newContainer;
        var oldContainer = sortData.oldContainer;
        //update published items count
        if(newContainer.parentElement != oldContainer.parentElement) {
          isContainerPublished(newContainer) ? updatePublishedCount(newContainer) : updatePublishedCount(oldContainer)
        } else if(isContainerPublished(newContainer)) {

          var element = newContainer.parentElement.querySelector('.published-media-container .dropzone-container .empty-slot .hc-media__medium span')
          var items = getFilteredItems(newContainer.parentElement);
          const availableSlots = parseInt(newContainer.getAttribute('data-available-slots'));

          if(items.length >= availableSlots)
            element.style.display = 'none'
        }

        var reviewId = parseInt(newContainer.getAttribute('data-id'));
        var dragItems = getFilteredItems(oldContainer.parentElement.parentElement);
        var hash = { published: { positions: {}}, unpublished: { positions: {}}}

        // check if position is updated
        if(sortData.oldIndex == sortData.newIndex && newContainer.parentElement == oldContainer.parentElement)
          return
        // generate params for the update position api
        Array.prototype.slice.call(dragItems).filter(item=> item.style.display !== 'none').forEach((item, index)=> {
          var id = item.getAttribute('data-media-id')
          const mediaType = item.parentElement.parentElement.dataset.type
          hash[mediaType].positions[id] = index + 1
        })

        hash.authenticity_token = $('[name="csrf-token"]')[0].content
        // ajax api call to update the position
        jQuery.ajax({
          method: "POST",
          url: "/cp/reviews/" + reviewId + "/media/update_positions",
          data: hash,
          dataType: 'json',
          success: function(resp) {
          },
          error: function(e) {
            console.log(e);
          }
        });
      });
    })
  }
};

function updatePublishedCount(container) {
  const items = getFilteredItems(container).length
  container.previousElementSibling.querySelector('.bubble').innerText = `(${items}/5)`
}

function isContainerPublished(container) {
  return container.parentElement.classList.contains('published-media-container')
}

function getFilteredItems(container) {
  return Array.prototype.slice.call(container.querySelectorAll('.drag-item')).filter(item=> item.style.display !== 'none')
}
