"use strict";

/*-----------------------------------------------
|   Sticky Kit
-----------------------------------------------*/
$(document).on('turbolinks:load', function() {
    if (window.is.ie()) {
        const stickyKits = $(".hcf-navbar__top");
        if (stickyKits.length) {
            stickyKits.each((index, value) => {
                const $this = $(value);
                const options = { ...$this.data("options") };
                $this.stick_in_parent(options);
            });
        }
    }
});
