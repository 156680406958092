'use strict';

// TODO add alias on webpack;
import utils from '../../../../../vendor/assets/javascripts/falcon/theme/Utils';

/*-----------------------------------------------
|   Navbar
-----------------------------------------------*/

const hasStorage = (function() {
  try {
    window.localStorage
    return true;
  } catch (exception) {
    return false;
  }
}());

$(document).on('turbolinks:load', function() {
  if (hasStorage) {
    const { $window } = utils;

    const ClassName = {
      NAVBAR_VERTICAL_COLLAPSED: 'navbar-vertical-collapsed',
      NAVBAR_VERTICAL_COLLAPSE_HOVER: 'navbar-vertical-collapsed-hover',
    };

    const Selector = {
      HTML: 'html',
      NAVBAR_VERTICAL: '.navbar-vertical',
      NAVBAR_VERTICAL_TOGGLE: '.navbar-vertical-toggle',
      NAVBAR_VERTICAL_COLLAPSE: '#aside',
      NAVBAR_VERTICAL_CONTENT: '.navbar-vertical-content',
      NAVBAR_VERTICAL_COLLAPSED: '.navbar-vertical-collapsed',
      NAVBAR_VERTICAL_LINK: '.hcf-aside__nav-linkJS:not(.--with-dropdown-indicator)', //class with suffix JS example
    };

    const Events = {
      CLICK: 'click',
      RESIZE: 'resize',
      SHOW_BS_COLLAPSE: 'show.bs.collapse',
      HIDDEN_BS_COLLAPSE: 'hidden.bs.collapse',
    };

    const $html = $(Selector.HTML);

    const breakPoints = {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1540
    };

    const isNavbarVerticalCollapsed = JSON.parse(
      localStorage.getItem('isNavbarVerticalCollapsed')
    );

    // Remove event on page changed
    utils.$document.off(Events.CLICK, Selector.NAVBAR_VERTICAL_TOGGLE);

    // Toggle navbar vertical collapse on click
    utils.$document.on(Events.CLICK, Selector.NAVBAR_VERTICAL_TOGGLE, (e) => {
      // Set collapse state on localStorage
      localStorage.setItem(
        'isNavbarVerticalCollapsed',
        !isNavbarVerticalCollapsed
      );

      // Toggle class
      if ($(window).width() < breakPoints.md) {
        $html.toggleClass(ClassName.NAVBAR_VERTICAL_COLLAPSE_HOVER);
      } else {
        $html.toggleClass(ClassName.NAVBAR_VERTICAL_COLLAPSED);
      }
    });

    // Remove event on page changed
    utils.$document.off(Events.CLICK, Selector.NAVBAR_VERTICAL_LINK);

    utils.$document.on(Events.CLICK, Selector.NAVBAR_VERTICAL_LINK, (e) => {
      if ($window.width() < breakPoints.md) {
        localStorage.setItem(
          'isNavbarVerticalCollapsed', 'false'
        );

        $html.removeClass(ClassName.NAVBAR_VERTICAL_COLLAPSE_HOVER);
      }
      const href = ($(e.target).closest('a').attr('href'));
      const id = localStorage.getItem('activeNavigation');

      if (!href.includes(id)) {
        localStorage.setItem(
          'activeNavigation', 'false'
        )
      }
    });

    // Set navbar aside on window resize
    $window.on(Events.RESIZE, () => {
      if ($window.width() > breakPoints.md) {
        localStorage.setItem(
          'isNavbarVerticalCollapsed', 'false'
        );

        $html.removeClass(ClassName.NAVBAR_VERTICAL_COLLAPSED);
      }
    });

    $(`#${localStorage.getItem('activeNavigation')}`).collapse('show');

    $('.collapse').on('shown.bs.collapse', function(e) {
      if ($(this).is(e.target)) {
        localStorage.setItem(
          'activeNavigation', e.target.id
        );
      }
    });
  }
});
