$(document).on('turbolinks:load', function() {
  $('.comment-form__body-input').on('input', function() {
    const replyButton = $(this).parent().find('.comment-form__chatgpt-reply')
    replyButton.toggle($(this).val().trim() === '')
  })

  $('.comment-form__submit').on('click', function() {
    const article         = $(this).closest('article')
    const url             = `/cp/reviews/${article.data().id}/update_with_chatgpt`
    const sentimentRating = article.find('.js-sentimentRating').data()?.id
    const chatgptTitle    = article.find('.js-chatgptTitle input').val()

    if ( sentimentRating || chatgptTitle ) {
      $.ajax({
        url: url,
        data: {
          review: {
            sentiment_rating: sentimentRating,
            chatgpt_title: chatgptTitle
          }
        },
        method: 'POST',
        beforeSend: function(xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))

          if ( window.sessionToken ) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + window.sessionToken)
          }
        }
      });
    }
  })

  // Click pencil icon to show input edit title
  $('.js-showInput').on('click', function() {
    const article = $(this).closest('article')
    const title   = article.find('#review-title')

    $(this).hide()
    article.find('.js-updateTitle').show()
    article.find('.js-hideInput').show()

    title.addClass('min-w-60').html(
      `<div class='review-title-wrapper'><input type='hidden' id='original-chatgpt-title' value='${title.text()}' /><input id='new-chatgpt-title' value='${title.text()}' /></div>`
    )
  })

  // Click cross icon to hide input edit title
  $('.js-hideInput').on('click', function() {
    const article       = $(this).closest('article')
    const title         = article.find('#review-title')
    const originalTitle = article.find('#original-chatgpt-title').val()

    $(this).hide()
    article.find('.js-updateTitle').hide()
    article.find('.js-showInput').show()

    title.removeClass('min-w-60').html(`<h2 class='review__title mb-0'>${originalTitle}</h2>`)
  })

  // Click check icon to update with chatgpt title
  $('.js-updateTitle').on('click', function() {
    const article       = $(this).closest('article')
    const title         = article.find('#review-title')
    const originalTitle = article.find('#original-chatgpt-title').val()
    const newTitle      = article.find('#new-chatgpt-title').val()

    $(this).hide()
    article.find('.js-hideInput').hide()
    article.find('.js-showInput').show()

    title.removeClass('min-w-60').html(`<h2 class='review__title mb-0'>${newTitle}</h2>`)

    if ( newTitle === originalTitle ) return

    const url = `/cp/reviews/${article.data().id}/update_chatgpt_title`

    $.ajax({
      url: url,
      data: {
        review: {
          chatgpt_title: newTitle
        }
      },
      method: 'POST',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))

        if ( window.sessionToken ) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + window.sessionToken)
        }
      },
      success: function(data) {
        if ( data?.message ) {
          hc_toast(data.message, 'success')
        }
      },
      error: function(data) {
        hc_toast(data.message, 'error')
      }
    })
  })
})
