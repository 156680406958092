'use strict';

/* -------------------------------------------------------------------------- */
/*                             Table Search filter                            */
/* -------------------------------------------------------------------------- */

$(document).on('turbolinks:load', function() {
    let table;

    /* Default class modification */
    $.extend($.fn.dataTable.ext.classes, $.fn.dataTable.ext.classes, {
        sTable: 'hcf-table',
        sNoFooter: '--no-footer',
        // Features
        sFilter: 'hcf-search-box hcf-search-boxJS',
        sLength: 'hcf-content-header__filter-dropdown hcf-content-header__lengthJS',
        sPaging: 'hcf-paginate hcf-paginateJS paging__',
        sInfo: 'hcf-paginate__info hcf-paginate__infoJS',
        // Filtering
        sFilterInput: 'hcf-search-box__input',
        // Page length
        sLengthSelect: 'hcf-content-header__filter-select',
        // Paging buttons
        sPageButton: "hcf-paginate__button",
        sPageButtonActive: "--current",
        sPageButtonDisabled: "--disabled",
    });
    $('.hcf-content-header__lengthJS').remove();
    $('.hcf-search-boxJS').remove();

    if ( ! $.fn.DataTable.isDataTable( '.hcf-table__targetJS' ) ) {
        table = $('.hcf-table__targetJS').DataTable({
            pagingType: 'simple',
            columnDefs: [
                { targets: 'no-sort', orderable: false }
            ],
            dom: '<"hcf-table__inner"flt><"hcf-table__bottom"ip>'
        });
    }

    const perPage = $('.hcf-content-header__lengthJS');
    const search = $('.hcf-search-boxJS');

    const loupe = $(`
        <svg height="1rem" width="1rem" fill="currentColor" class="hcf-search-box__icon">
            <use xlink:href="#hcf-search"/>
        </svg>
    `)

    perPage.detach();
    search.detach();
    search.find('label').contents()
        .filter(function() {
            return this.nodeType == 3; //Node.TEXT_NODE
        }).remove()
    search.find('input').attr('placeholder', 'Search');
    search.find('input').after(loupe);

    $('.hcf-content-header__show-pagesJS').append(perPage);
    $('.hcf-content-header__searchJS').append(search);
});
