hc_toast = function(message, style, params) {
  const default_params = {
    title: false,
    content: '<em>No content</em>',
    delay: 5000,
    position: 'bottom center',
    style: 'info',
    autohide: true,
    close: true,
    link: false,
    close_link: false
  };
  const default_title = {
    text: '',
    color: '',
    icon: null,
    image: null,
    info: false,
  };

  if (null === message) return;
  if (params === undefined) params = {};
  _open(message, style, params);

  function _get_container(config) {
    let position = (config.position || 'top right').split(' ');
    let ver = position[0] || 'top';
    let hor = position[1] || 'right';

    if (!['top', 'bottom'].includes(ver)) ver = 'top';
    if (!['left', 'center', 'right'].includes(hor)) hor = 'right';

    position = `${ver} ${hor}`;

    // Find existing container
    container_selector = '.toast-container[data-position="'+position+'"]';
    if (jQuery(container_selector).length > 0) return jQuery(container_selector).first();

    // if (hc_toast_container[position]) return hc_toast_container[position];

    let html = _make_container(ver, hor);

    return jQuery(html).appendTo('body');
  }

  function _make_body(config) {
    // Add link to content if present
    let content = !config.link ?
      config.content :
      `<a href="${config.link}" target="_blank">${config.content}</a>`
    return `
      <div class="toast-body">
        ${content}
      </div>`;
  }

  function _make_container(ver, hor) {
    let css = `position:fixed;width:300px;${ver}:60px;z-index:1060;`;
    if (hor === 'center') css += 'left:50%;margin-left:-160px';
    else css += `${hor}:20px`;

    return `<div class="toast-container" data-position="${ver} ${hor}" aria-live="polite" aria-atomic="true" style="${css}"></div>`;
  }

  function _make_header(config) {
    if (!config.title) return '';

    if (typeof config.title === 'string') config.title = { text: config.title };

    let title = { ...default_title, ...config.title };

    let e_image = '';
    if (title.image) e_image = `<img src="${title.image}" class="rounded mr-2" alt="#">`;
    else if (title.icon) e_image = `<i class="${title.icon} mr-2"></i>`;

    let e_title = !title.text ? '' : `<strong class="mr-auto" style="color: ${title.color}">${title.text}</strong>`;

    let e_info = !title.info ? '' : `<small>${title.info}</small>`;

    // Display close button?
    let e_close = !config.close
      ? ''
      : _make_close_button(config);

    return `
      <div class="toast-header">
        ${e_image}
        ${e_title}
        ${e_info}
        ${e_close}
      </div>
    `;
  }

  function _make_html(config) {
    let header = _make_header(config);
    let body = _make_body(config);

    return `
      <div class="toast toast-${config.style}" role="alert" aria-live="assertive" aria-atomic="true">
          ${header} ${body}
      </div>`;
  }

  function _make_close_button(config) {
    // Add the link to the close button if applicable
    return !config.close_link
      ? ` <button type="button" class="toast-close-button ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>`
      : ` <a href="${config.close_link}" class="toast-close-button ml-2 mb-1 close" data-remote="true" data-method="patch" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </a>`;
  }

  function _open(message, style, params) {
    if (typeof message !== 'object')
      params["content"] = message
    if ((typeof style !== 'object') && (style !== undefined))
      params["style"] = style;
    const config = { ...default_params, ...params };
    const html = _make_html(config);

    jQuery(html)
      .appendTo(_get_container(config))
      .toast({
        animation: true,
        autohide: config.autohide,
        delay: config.delay,
      })
      .toast('show')
      .on('hidden.bs.toast', function () {
        jQuery(this).remove();
      });
  }
}
