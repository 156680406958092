/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/packs and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'regenerator-runtime/runtime'
import 'datatables.net/js/jquery.dataTables'
import 'plugins/draggable'
import 'plugins/hc_toast'
import 'flatpickr'

var hasStorage = (function() {
  try {
    window.localStorage
    return true;
  } catch (exception) {
    return false;
  }
}());

if (hasStorage) {
  require('src/oauthio')
}

import 'src/shopify_app_bridge'
import 'src/chatgpt_reply'

// Bootstrap components
import 'falcon/bootstrap/util.js'
import 'falcon/bootstrap/dropdown'
import 'falcon/bootstrap/collapse'
import 'falcon/bootstrap/tooltip'
import 'falcon/bootstrap/popover'
import 'falcon/bootstrap/modal'
import 'falcon/bootstrap/toast'

// Falcon components
import 'falcon/theme/config.navbar-vertical'
import 'falcon/theme/dropdown-menu'
import 'falcon/theme/stickykit'
import 'falcon/theme/tooltip'
import 'falcon/theme/modal'

// Own Components
import 'admin/components/navbar'
import 'admin/components/dropdown-on-hover'
import 'admin/components/stickykit'
import 'admin/components/bulk-select'
import 'admin/components/search'
import 'admin/components/form'
import 'admin/components/select2'
