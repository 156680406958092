$(document).on('turbolinks:load', function() {
  const provider      = $('section.social-account').attr('id')
  const connectButton = $('section.social-account').find(`.social-accounts__connect-${provider}`)
  const reconnectButton = $('section.social-account').find(`.social-accounts__reconnect-${provider}`)

  connectButton.on('click', oauthPopup);
  reconnectButton.on('click', oauthPopup);

  function oauthPopup() {
    let wnd_settings = {
      width: Math.floor(window.outerWidth * 0.8),
      height: Math.floor(window.outerHeight * 0.5)
    };
  
    if (wnd_settings.width < 1000) {
      wnd_settings.width = 1000;
    }
    if (wnd_settings.height < 630) {
      wnd_settings.height = 630;
    }

    let screenX     = typeof window.screenX != 'undefined' ? window.screenX : window.screenLeft,
    screenY     = typeof window.screenY != 'undefined' ? window.screenY : window.screenTop,
    outerWidth  = typeof window.outerWidth != 'undefined' ? window.outerWidth : document.body.clientWidth,
    outerHeight = typeof window.outerHeight != 'undefined' ? window.outerHeight : (document.body.clientHeight - 22),
    left        = parseInt(screenX + ((outerWidth - wnd_settings.width) / 2), 10),
    top         = parseInt(screenY + ((outerHeight - wnd_settings.height) / 2.5), 10),
    features    = ('width=' + wnd_settings.width + ',height=' + wnd_settings.height + ',left=' + left + ',top=' + top);

    let newwindow = window.open(`/auth/${provider}`, 'Login', features);

    if (window.focus)
      newwindow.focus();
  }
})

