'use strict';

import 'select2';

/*-----------------------------------------------
|   Select2
-----------------------------------------------*/

$(document).on('turbolinks:load', function() {
    const select2 = $('.js__select');
    select2.length &&
    select2.each((index,value) => {
        const $this = $(value);
        const options = $.extend({
            theme: 'bootstrap4',
            width: 'resolve'
        }, $this.data('options'));
        $this.select2(options);
    });
});
